// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import "@coreui/coreui-pro/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";
@import "example";

// If you want to add custom CSS you can put it here.
@import "custom";

.breadcrumbLinkStyle {
  color: blue;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.breadcrumb {
  --cui-breadcrumb-margin-bottom: 0;
}

.accordion-item {
  --cui-accordion-btn-padding-y: 0.5rem;
}

.form-multi-select-selection-tags {
  padding-right: 12px !important;
  --cui-form-multi-select-indicator: null;
}

.form-select {
  min-width: 60px !important;
}

td {
  word-break: break-word;
  min-width: 60px;
}

.tableContainer{
  width: 100%;
  display: block;
  overflow-x: auto;
  margin-bottom: 10px;
}

.tableContainer .table {
  min-width: 1250px;
}
 

.tableContainer th,
.tableContainerA td {
  border-right: 1px solid #4f5d7395;
}

.tableContainer th:last-child,
.tableContainer td:last-child {
  border-right: none;
}

:root {
  scrollbar-color: #4F5D73 #f5f5f5 !important;
  scrollbar-width: thin !important;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3) !important;
  background-color: #f5f5f5;
}
::-webkit-scrollbar {
  width: 6px !important;  
  height: 3px !important;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #4F5D73;
  width: 6px !important;
  height: 3px !important;
} 

td:has(input),
td:has(.updatesColumn),
td:has(.deleteColumn)
 {
  text-align: center;
}