// Here you can add other styles
.service-health-dashboard {
  display: flex;
}

#build-hash {
  a {
    color: #999;
    text-decoration: none;
  }

  font-size: 13px;
  text-align: right;
  width: 100%;
}

.clickable {
  cursor: pointer;
}

.dropdown-custom {
  width: 300px;
}

.scrollbar {
  overflow: auto;
  width: 100%;
  max-height: 300px;
}

.table-id-link {
  text-decoration: none;
}

.table-id-link:hover {
  text-decoration: underline;
}